/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, PriceWrap, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-172m53e css-wxu2wu --style2 --full pb--60 pt--60" name={"z09x3dkj8ae"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/18947/75c7bd8b624f4a08aa1741aedc106d40_s=3000x_.png);
    }
  
background-position: 50% 64%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--white)\">O nás</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--60" name={"fny11cwnts"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left" style={{"maxWidth":1190}} content={"<span style=\"color: rgb(0, 0, 0);\">Přijďte ochutnat naši kuchyni a kochejte se unikátním výhledem\nna Národní muzeum třeba u oběda, v letních měsících navíc\nmůžete posedět na zahrádce.\nOd pondělí do pátku pro vás připravujeme polední menu, a to v\nčase od 11 do 15 hodin.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border2 --center pt--0" name={"64mzimgd6pa"} style={{"backgroundColor":"rgba(96, 96, 96, 1)"}} border={"2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">Sídlíme na výjimečné adrese</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"eppdb93am1b"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left" style={{"maxWidth":1233}} content={"<span style=\"color: rgb(0, 0, 0);\">Najdete nás v Nové i Historické budově Národního muzea. Spojte návštěvu\nmuzea s obědem nebo kávou a dezertem u nás, rádi se o vás postaráme v\nobou našich provozovnách.\nVaříme moderní českou klasiku a mezinárodní kuchyni.Ve stálé nabídce\nnajdete výběr salátů, pizzy a domácí plněné ciabatty.<br><br>Jsme parta mladých nadšenců, pro které je gastronomie stále srdeční záležitost. Součástí\nnašeho portfolia jsou nejen bistra a kavárny, ale i cateringová společnost. Vše se stále\nsnažíme posouvat dál a vytvářet pro naše zákazníky nadstandardní servis. Reagujeme na\nrychlý a moderní vývoj na trhu, který prezentujeme našim hostům v&nbsp;podobě našich\nproduktů, servisu a služeb.\nNejlépe se přesvědčíte, když nás vyzkoušíte, ať už při návštěvě našich provozů, nebo\ncateringem na míru, který vám rádi připravíme u vás doma, nebo ve firmě.<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vh1wfj16rsp"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/a456cf491256431e80adc9a7699b8110_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/a456cf491256431e80adc9a7699b8110_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/a456cf491256431e80adc9a7699b8110_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/a456cf491256431e80adc9a7699b8110_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/a456cf491256431e80adc9a7699b8110_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/a456cf491256431e80adc9a7699b8110_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/aeea1c84a93446ebaef9f0d1f79ec73d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/aeea1c84a93446ebaef9f0d1f79ec73d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/aeea1c84a93446ebaef9f0d1f79ec73d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/aeea1c84a93446ebaef9f0d1f79ec73d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/aeea1c84a93446ebaef9f0d1f79ec73d_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/d6d47162ce854b97aedc046fc698664d_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/d6d47162ce854b97aedc046fc698664d_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/d6d47162ce854b97aedc046fc698664d_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/d6d47162ce854b97aedc046fc698664d_s=860x_.png 860w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=1400x_.png 1400w, https://cdn.swbpg.com/t/18947/e8c4ba6f658e4c10b84d57936495c1a5_s=2000x_.png 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/4eefa35df2564620979e526c81d4b60a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/4eefa35df2564620979e526c81d4b60a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/4eefa35df2564620979e526c81d4b60a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/4eefa35df2564620979e526c81d4b60a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/4eefa35df2564620979e526c81d4b60a_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/1ad9fd5640fb426da0a023fe3c155dab_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/1ad9fd5640fb426da0a023fe3c155dab_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/1ad9fd5640fb426da0a023fe3c155dab_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/1ad9fd5640fb426da0a023fe3c155dab_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/d5168c1d7a0e44d1a9d77e059bfc394d_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/f10ae070cf914f5ea6a65dea1d41d64c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":720}} srcSet={"https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/0ee323c61d8346d8add45ee0b51fbd20_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/18947/829b00421a26471e9fc82585c3d22227_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":694}} srcSet={"https://cdn.swbpg.com/t/18947/829b00421a26471e9fc82585c3d22227_s=350x_.jpg 350w, https://cdn.swbpg.com/t/18947/829b00421a26471e9fc82585c3d22227_s=660x_.jpg 660w, https://cdn.swbpg.com/t/18947/829b00421a26471e9fc82585c3d22227_s=860x_.jpg 860w, https://cdn.swbpg.com/t/18947/829b00421a26471e9fc82585c3d22227_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/18947/829b00421a26471e9fc82585c3d22227_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-gqd6tz pb--60 pt--60" name={"ft3rndkx4"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/18947/a0a4c310f62a4b09a1229311c43f84b1_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--8" content={""}>
                  </Text>

                  <Text className="text-box text-box--center fs--20" content={"<span style=\"color: rgb(8, 8, 8); font-weight: bold;\">Nová budova Národního muzea</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<span style=\"font-weight: bold;\">PO - NE</span>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"9:30-19:00"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={""}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"Telefon: +420 257 310 852"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box text-box--center fs--30" content={""}>
                  </Text>

                  <Text className="text-box text-box--center fs--20" content={"<span style=\"color: rgb(0, 0, 0);\">Telefonické objednávky od 10:00 – 15:00 na telefonu: +420 257 310 852&nbsp;</span><br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--30" content={""}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="el--1" style={{"backgroundColor":"#f4f4f4"}} columns={"1"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--8" content={""}>
                  </Text>

                  <Text className="text-box text-box--center fs--20" content={"<span style=\"color: rgb(8, 8, 8); font-weight: bold;\">Historická budova Národního muzea</span><br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<span style=\"font-weight: bold;\">PO - NE</span><br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"10:00 - 18:00<br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"<br>"}>
                  </Text>

                  <Text className="text-box text-box--center fs--18" content={"Telefon: +420 220 960 458<br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--left" style={{"maxWidth":192}} alt={""} src={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=660x_.png 660w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=860x_.png 860w, https://cdn.swbpg.com/t/18947/ecbb0ce72a4c4111a213e39f1d862127_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">E-mail</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"info@bistronarodnimuzeum.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Telefon</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"+420 257 310 852"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Subtitle className="subtitle-box subtitle-box--center mt--02" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(0, 0, 0);\">Sledujte nás</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.facebook.com/bistronarodnimuzeum\" target=\"_blank\">Facebook</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--14" style={{"maxWidth":256}} content={"<a href=\"https://www.instagram.com/bistronarodnimuzeum/\" target=\"_blank\">Instagram</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}